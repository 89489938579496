<template>
    <div :class="`${customClass}`" class="space-y-1">
        <label v-if="label" class="block text-sm font-medium text-gray-700">{{
            label
        }}</label>
        <div class="relative rounded-md shadow-sm">
            <div
                class="
                    absolute
                    inset-y-0
                    left-0
                    flex
                    items-center
                    pl-3
                    pointer-events-none
                "
            >
                <Icon
                    :name="icon"
                    color="gray-400"
                    customClass="flex-shrink-0 ml-1"
                    hoverTextColor="gray-500"
                    size="5"
                />
            </div>
            <input
                type="text"
                v-model="inputValue"
                class="
                    block
                    w-full
                    h-10
                    pl-10
                    border border-gray-500
                    rounded
                    sm:text-sm
                    focus:outline-none focus:ring-1 focus:ring-primary-500
                "
                :placeholder="placeholder"
                style="min-width: 150px !important"
                autocomplete="off"
            />
        </div>
    </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";

/**
 * - Use it if needs to show input form with leading icon
 * - icon is required
 */
export default {
    components: {
        Icon,
    },
    props: {
        /**
         * Use to show what input form is for
         */
        label: {
            type: String,
            default: "",
        },
        /**
         * Placeholder of input form
         */
        placeholder: {
            type: String,
            default: "",
        },
        /**
         * Use it to apply extra class to input form
         */
        customClass: {
            type: String,
            default: "",
        },
        /**
         * Use to catch dynamic value of input form
         */
        value: {
            type: String,
            default: "",
        },
        /**
         * Use to specify leading icon. its required. ex: MailIcon
         */
        icon: {
            type: String,
            default: "",
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("update:value", newValue);
            },
        },
    },
};
</script>
