<template>
    <Listbox v-model="selectedValue" as="div">
        <ListboxLabel
            v-if="label"
            class="block text-sm font-semibold text-gray-700"
        >
            {{ label }}
        </ListboxLabel>
        <div class="relative mt-1 flex flex-col flex-1">
            <a
                @click="isOpen = true"
                class="relative w-full py-2 h-10 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            >
                <span v-if="selectedValue" class="flex items-center">
                    <span class="inline-flex w-full truncate space-x-3">
                        <Text
                            :content="getValue()"
                            custom-class="truncate sm:text-sm"
                        />
                    </span>
                </span>
                <span class="text-gray-400" v-else>{{ placeholder }}</span>
            </a>
            <span
                @click="isOpen = true"
                class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 flex items-center space-x-1 z-10"
            >
                <Icon name="SelectorIcon" color="gray-400" size="5" />
            </span>
            <div
                v-if="isOpen"
                v-click-outside="() => (isOpen = false)"
                class="absolute left-0 top-10 bg-white border rounded-md py-1 overflow-auto z-50"
                style="min-width: 200px; max-height: 400px"
            >
                <div class="" v-for="item in items" :key="item.id">
                    <Text
                        @click="
                            () => {
                                if (!item.children) {
                                    selectedValue = {
                                        parent: item,
                                        child: null,
                                    };
                                    isOpen = false;
                                }
                            }
                        "
                        size="xs"
                        color="black"
                        :content="item.name"
                        custom-class="w-full py-2 bg-gray-100 px-2"
                    />
                    <div v-for="subItem in item.children">
                        <Text
                            @click="
                                () => {
                                    selectedValue = {
                                        parent: item,
                                        child: subItem,
                                    };
                                    isOpen = false;
                                }
                            "
                            size="xs"
                            color="black"
                            :content="subItem.name"
                            :custom-class="`${
                                get(selectedValue, 'parent.name') ===
                                    get(item, 'name') &&
                                get(selectedValue, 'child.name') ===
                                    get(subItem, 'name')
                                    ? 'bg-indigo-400 text-white hover:bg-indigo-500'
                                    : 'bg-white text-black hover:bg-gray-100 '
                            } w-full py-2 px-2 cursor-pointer pl-4`"
                        />
                    </div>
                </div>
            </div>
        </div>
        <Text
            size="xs"
            :content="helpText"
            weight="normal"
            color="gray-400"
            custom-class="mt-1 italic"
            v-if="helpText"
        />
    </Listbox>
</template>

<script>
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Avatar from "../../../atoms/Avatar/Avatar.vue";
import vClickOutside from "click-outside-vue3";
import { get } from "lodash";

export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        Icon,
        Text,
        Avatar,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        helpText: {
            type: String,
            default: "",
        },
        customClass: {
            type: String,
            defalut: "",
        },
        /**
         * items to show in select
         */
        items: {
            type: Array,
            default: () => [],
        },
        /**
         * used to set predefined value.
         */
        selectedItem: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    methods: {
        getValue() {
            if (this.selectedValue?.parent) {
                return `${this.selectedValue?.parent?.name}${
                    this.selectedValue?.child?.name
                        ? ` - ${this.selectedValue?.child?.name}`
                        : ""
                }`;
            } else {
                return this.placeholder;
            }
        },
    },
    data() {
        return {
            isOpen: false,
            get,
        };
    },
    computed: {
        selectedValue: {
            get() {
                return this.selectedItem;
            },
            set(newValue) {
                this.$emit("update:selectedItem", newValue);
            },
        },
    },
};
</script>
