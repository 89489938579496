<template>
    <div class="flex flex-col p-4">
        <div class="flex items-start lg:items-start justify-between gap-2">
            <div class="
                                                                        flex
                                                                        items-start
                                                                        lg:items-start
                                                                        flex-1
                                                                        gap-2
                                                                        mr-2
                                                                        mt-1.5
                                                                        lg:mt-0
                                                                    ">
                <Text color="black" weight="bold" :size="'xl'" :custom-class="isSub ? 'sm:text-xl': 'sm:text-3xl'" :content="title" />
                <Badge v-if="count" :label="count.toString()" px="3" textColor="gray-600" bgColor="gray-300" py="1"
                    isRounded textSize="lg" textWeight="bold" customClass="flex-shrink-0" />
            </div>
            <div class="flex gap-2 items-center">
                <InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="searchbox && searchbox.placeholder"
                    :icon="searchbox && searchbox.icon" class="hidden lg:block min-w-min" v-model:value="keyword" />
                <div class="add-btn">
                    <ButtonWithIcon v-if="!isNotAdd" :click="() => $emit('onAdd')" :label="btnLabel" iconName="PlusIcon"
                        variant="primary" size="md" class="h-10 lg:h-10" />
                </div>
            </div>
        </div>
        <InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="searchbox && searchbox.placeholder"
            :icon="searchbox && searchbox.icon" class="block lg:hidden mt-2" v-model:value="keyword" />
        <div class="flex items-center justify-between w-full mt-4 text-gray-500">
            <div class="flex item-center">
                <Select v-if="filter" class="mr-2" :items="filter.items" placeholder="Filter"
                    v-model:selectedItem="currentFilter" />
                <Select v-if="sort" :items="sort.items" placeholder="Sort" :customClass="filter ? 'right-0 w-fit' : ''"
                    v-model:selectedItem="currentSort" />
            </div>
        </div>
    </div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import ButtonWithIcon from "../../Button/WithIcon/WithIcon.vue";
import Select from "../../Selects/WithCategory/WithCategory.vue";
import InputWithLeadingIcon from "../../Inputs/InputWithLeadingIcon/InputWithLeadingIcon.vue";
import Link from "../../../atoms/Link/Link.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";

export default {
    components: {
        Text,
        ButtonWithIcon,
        InputWithLeadingIcon,
        Link,
        Select,
        Badge,
    },
    name: "ListViewHeader",
    props: {
        title: {
            type: String,
            default: "",
        },
        count: {
            type: Number,
            default: 0,
        },
        searchbox: {
            type: Object,
            default: () => { },
        },
        filter: {
            type: Object,
            default: () => { },
        },
        sort: {
            type: Object,
            default: () => { },
        },
        links: {
            type: Object,
            default: () => { },
        },
        isShowSearchBox: {
            type: Boolean,
            default: true,
        },
        searchText: {
            type: String,
            default: "",
        },
        btnLabel: {
            type: String,
            default: "Add Contact",
        },
        isNotAdd: {
            type: Boolean,
            default: false,
        },
        onSearchTextChange: {
            type: Function,
            default: () => { },
        },
        selectedSort: {
            type: Object,
            default: () => {}
        },
        isSub: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            keyword: this.searchText,
            currentFilter: null,
            currentSort: this.selectedSort,
        }
    },
    emits: ['onSort', 'onFilter', 'onAdd'],
    watch: {
        searchText(val) {
            this.keyword = val;
        },
        keyword(val) {
            this.onSearchTextChange(val);
        },
        currentFilter: function (val) {
            this.$emit('onFilter', val)
            if (val?.parent?.name === 'Clear') {
                this.currentFilter = null;
            }
        },
        currentSort: function (val) {
            this.$emit('onSort', val)
            if (val?.parent?.name === 'Clear') {
                this.currentSort = null;
            }
        }
    }
};
</script>
<style scoped>
.add-btn {
    min-width: 104px;
}
</style>
